.France2024Pg{

    z-index: 99999;
    background-color: white;
    position: absolute;

}

.GoToHomeBtCont{
    display: flex;
    justify-content: right;
    width: auto;
}

.GoToHomeBt{

    border-radius: .6rem;
    border: 1px solid darkred;
    color: darkred;
    padding: .6rem 1rem;
    
    font-size: .8rem;
    margin: 1rem 1rem;
    cursor: pointer;

}

.GoToHomeBt:hover {

    background-color: darkred;
    color: white;
    border: 1px solid  transparent;

}
    

.LogoINDENEPAL{

    width: 50%;
}

.Head1{

    font-size: 1.6rem;
    font-weight: 800;
    text-align: center;
    color: rgb(208, 101, 1);

    padding: 4rem  0 1rem 0 ;

}

.PopHead1 {

    font-size: 1rem;
    font-weight: 800;
    text-align: center;
    color: rgb(208, 101, 1);
    padding: 2rem ;
    margin: 1rem;
    /* margin: 10%; */
    /* height: 2rem; */
    /* padding: 10%; */
    /* padding: 5rem  0 1rem 0 ; */
    width: 30%;

}

.Head2{

    font-size: 5rem;
    font-weight: 700;
    text-align: center;
    color: rgb(208, 101, 1);
    


}

.PopHead2{

    font-size: 5rem;
    font-weight: 700;
    text-align: center;
    color: rgb(208, 101, 1);
    width:30%;
    


}
.Subhead1{

    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: rgb(208, 101, 1);

}

.Subhead2{

    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    padding: 4rem  0 1rem 0 ;
   color: rgb(208, 101, 1);


}

.Sect2ColCont{

    display: flex;
    justify-content: center;
}

.IconCont{

    background-color: rgb(208, 101, 1);
    border-radius: 1.5rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 0 0;
    
}
.IconCont img{
    width: 1.6rem;
    
}


.Colm{

    color: rgb(208, 101, 1);
    background-color: rgb(251, 253, 255);
    border-radius: 1rem;
    width: 25%;
    padding: 2rem 2rem;
    margin: 2rem 1rem;



}

.SubHeadSec2{
    
    font-weight: 700;
    display: flex;
    /* justify-content: center; */
    align-items: center;

}
.BodyTextSec2{
    
    color: rgb(208, 101, 1);
    margin: 1rem 0;
    line-height: 150%;

}



.Head3{

    font-size: 2.6rem;
    font-weight: 700;
    text-align: center;
    padding: 8rem 1rem 2rem 1rem;
    color: white;


}


.Section1{

    min-height: 20rem;
    background-color: rgb(167, 167, 167);
    margin: 2rem;
    border-radius: 1rem;
    background-image: url('./TajMahal.jpg');

    background-repeat: no-repeat;
   
    background-size: cover;
    /* background-position-x: 10%; */
    background-position-y: 40%;
    background-blend-mode:overlay;
    
    


}

.PopupSection1{

    min-height: 20rem;
    background-color: rgb(167, 167, 167);
    margin: 2rem;
    border-radius: 1rem;
    background-image: url('./TajMahal.jpg');

    background-repeat: no-repeat;
   
    background-size: cover;
    /* background-position-x: 10%; */
    background-position-y: 40%;
    background-blend-mode:overlay;
    
    


}


.Section2{

    min-height: 20rem;
    background-color: rgb(255, 255, 255);
    margin: 2rem;
    border-radius: 1rem;




}


.Section3{

    min-height: 30rem;
    background-color: rgb(220, 220, 174);
    margin: 0rem;
    padding: 2rem 0;
    border-radius: 0rem;


    display: flex;
    justify-content: center;
    text-align: center;



}
.ColmSec3{

    width: 20%;
    margin: 2rem .6rem;
    padding: 1rem 1rem  2rem 1rem;
    background-color: white;
    border-radius: 2rem;

}

.ImgSec3{

    width: 100%;
    height:  10rem;
    border-radius: 1rem;
    overflow: hidden;

}

.ImgSec3 img{
    width: 100%;


}

.ExTitle{

    font-size: .6rem;
    /* font-weight: 700; */
    letter-spacing: 1px;
    color: white;
    padding: .2rem .2rem;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    
    text-align: center;
    margin-bottom: .5rem;
    margin-top: .5rem;
    /* border: 1px solid ; */
    border-radius: 1rem;
    background-color: rgb(208, 208, 208);


}

.ExDet{
    font-size: .8rem;
    font-weight: 650;
    padding: .4rem .4rem;

}

.Orange{
    color: rgb(208, 101, 1);

}

.OrangeBg{
    background-color: rgb(208, 101, 1);
}

.Gold{
    color: goldenrod;

}
.GoldBg {
    background-color: goldenrod;


}


.Green{
    color: green;

}
.GreenBg{
    background-color: green;

}

.DarkGreen{
    color: darkgreen;

}
.DarkGreenBg{
    background-color: darkgreen;

}



.SubheadSec3{

    font-size: 1.6rem;
    font-weight: 700;
    margin: 2rem  0 1rem 0rem;
    text-align: center;
    min-height: 4rem;
    letter-spacing: 2px;
}

.TextSec3{
    text-align: center;


}



.Section4{

    min-height: 20rem;
    /* background-color: ; */

    margin: 1rem;
    border-radius: 1rem;
    padding-bottom: 4rem;

    background-image: url('./Images/Background1.jpg');

    background-repeat: repeat;
    
    background-size: cover;
    /* background-position-x: 10%; */
    background-position-y: 40%;
    /* background-blend-mode:overlay; */

}

.Section4 .Logos1{
    width: 80%;
    height: 12rem;
    padding: 2rem;
    margin: 0 auto;


}

.Section5{

    min-height: 20rem;
    /* background-color: rgb(192, 253, 255); */

    margin: 2rem;
    padding: 2rem;
    border-radius: 1rem;

    display: flex;
    justify-content: center;





}

.ColmSec5{

    width: 40%;
    min-height: 20rem; 
    border-radius: 1rem;


}

.GridTeam1{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1rem 0;

    
}

.Flag1{

    width: 3rem;
    margin: 1rem 0rem;


}

.Flag1 img{
    width: 100%;
}

.Team1{

    width: 8rem;
    border-radius: 4rem;
    margin: .4rem .4rem;
    text-align: center;
}

.Text5{

    font-size: .9rem;
    text-align: center;
    margin: 0 0 1rem 0;

}
.Text5a{
    font-size: .9rem;
    letter-spacing: 3px;

}


.Section6{

    min-height: 6rem;
    background-color: darkred;

    /* margin: 2rem; */
    /* border-radius: 1rem; */
    padding: 2rem;
    font-size: .8rem;
    color: white;




}

.DestGrid1{

    display: flex;
    justify-content: center;


}

.Dest{

    margin: .6rem;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    padding: 2rem 5%;
    width: 25%;
    min-height: 30rem; 

    justify-content: center;

    text-align: center;
    font-size: .8rem;
    position: relative;


}

.KnowmoreBtCont{
    display: flex;
    justify-content: center;

}

.KnowmoreBt{

    border-radius: .6rem;
    border: 1px solid darkred;
    padding: .6rem 1rem;
    
    font-size: .7rem;
    /* margin-top: 4rem ; */
    cursor: pointer;
    text-decoration: none;
    color: darkred;
    
    position: absolute;
    bottom: 2rem;
    left: auto;
    right: auto;
    text-align: center;

    /* margin: 0 auto; */



}

.KnowmoreBt:hover{
    background-color: darkred;
    color: white;
    border: 1px solid transparent ;


}

.Txt4{




}

.FooterCont{
    margin-left: 10%;

}


.PopupCont{

    width: 80%;
    /* background-color: rgba(255, 255, 255, 0.9); */
    background-color: rgba(208, 101, 1, 0.9);
    /* border: 1px solid darkred; */
    opacity: 1;
    position: fixed;
    top: 5%;
    /* bottom: 2%; */
    left: 2%;
    right: 2%;
    z-index: 999999;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
    

}

.PopupCont:hover{
    /* background-color: rgba(253, 226, 226, 0.9); */
    background-color: rgba(177, 87, 2, 0.9);
    /* border: 1px solid transparent; */

}

.SectionPopUp{

    min-height: 10rem;
    background-color: rgb(255, 255, 255);
    margin: 2rem;
    border-radius: 1rem;
    /* background-image: url('./TajMahal.jpg'); */

    background-repeat: no-repeat;
   
    background-size: cover;
    /* background-position-x: 10%; */
    background-position-y: 40%;
    background-blend-mode:overlay;

    display: flex;

    justify-content:left;
    align-items: center;

    
    


}


.SectionPopUp:hover {

    background-color: greenyellow;

}

.LogoIFTM{

    width: 60%;
    /* height: ; */
    /* margin:10%; */

    align-self: center;
    align-items: center;
    

    /* margin-top: 50% ;  */


}

.LogoINDENEPAL{

    width: 60%;
}
.Head1{

    padding: 4rem 0 0 0 ;
}

.PopUpCloseBt{
    
    /* background: white; */
    color: white;
    background-color: black;
    padding: .4rem .4rem;
    font-size: .8rem;
    font-weight: 500;
    border-radius: .4rem;
    width: 4rem;
    text-align: center;
    margin: .2rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}
.PopUpCloseBt:hover{

    background-color: rgb(14, 221, 31);
    color: black;
}

.PopCTABt{

    /* background: white; */
    color: white;
    margin: 1.5rem;
    padding: .6rem .4rem;
    font-size: .8rem;
    font-weight: 700;
    border-radius: .4rem;
    /* background-color: rgba(208, 101, 1, 0.9); */
    background-color: black;
    /* width: 6rem; */
    width: 30%;
    text-align: center;
    /* margin-top: 50%; */

    /* position: absolute; */
    /* right: 1rem; */
    /* top: 1rem; */
    cursor: pointer;



}

.PopCTABt:hover {
    /* background-color: rgba(177, 87, 2, 0.9); */
    background-color: white;
    color: black;

}

.PopCTABt:hover .Link{
    color: black;
}


@media screen and (max-width: 800px) {

    .SectionPopUp{

        min-height: 16rem;
        background-color: rgb(255, 255, 255);
        margin: .6rem;
        border-radius: 1rem;
        /* background-image: url('./TajMahal.jpg'); */
    
        /* background-repeat: no-repeat; */
       
        /* background-size: cover; */
        /* background-position-x: 10%; */
        /* background-position-y: 40%; */
        /* background-blend-mode:overlay; */
    
        display: block;
        justify-content: center;
        align-items: center;
    
    }    
    

    .PopHead1 {

        font-size: 1rem;
        font-weight: 800;
        text-align: center;
        color: rgb(208, 101, 1);
        margin: 1rem;
        margin: 0 auto;
        width: 50%;
        /* margin: 10%; */
        /* height: 2rem; */
        /* padding: 10%; */
        /* padding: 5rem  0 1rem 0 ; */
        /* margin: 1rem 0 0rem 0 ; */
        
        padding: .2rem 0 1.4rem 0 ;
    
    }

    .PopCTABt{
        
        text-decoration: none;
        margin: 1rem 1rem ;
        padding: .6rem .4rem;
        margin: 0 auto;
        text-align: center;
        /* margin-left: auto; */
        /* margin-right: auto; */
        /* margin-bottom: 2rem; */
        /* position: absolute; */
        /* bottom: 2rem; */
        width: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

    }


    .LogoIFTM{

        width: 100%;
        
        /* margin:2%; */
    
        align-self: center;
        align-items: center;
        
    
        /* margin-top: 50% ;  */
    
    
    }

    .PopupCont{
        width: auto;
        top:0%;
        left: 0rem;
        right: 0rem;
        border-radius: 0;
        height: 100%;
    }

.PopupSection1{

    background-color: rgb(253 231 192);
    background-position-y: 40%;
    background-blend-mode: overlay;
    margin: .6rem;
    min-height: 15rem;

   


}

.Section1{

    background-color: rgb(253 231 192);
    background-position-y: 40%;
    background-blend-mode: overlay;

    min-height: 20rem;
    background-color: rgb(167, 167, 167);
    margin: .4rem;
    border-radius: 1rem;
    background-image: url('./TajMahal.jpg');

    background-repeat: no-repeat;
   
    background-size: cover;
    background-position: 0%;
    background-position-x:center;
    /* background-size: auto; */
    width: auto;
   
    
    

   


}

.PopHead2{



    width:30%;
    
    margin: 0 auto;

}
.Subhead2{

    font-size: 2rem;
    padding: 1rem 0;
}
.GoToHomeBtCont{
    display: flex;
    justify-content: right;
    width: auto;
}

.GoToHomeBt{

    margin: .4rem .8rem;
}


.Sect2ColCont{

    display: block;
    /* justify-content: center; */
}



.Colm{

    color: rgb(208, 101, 1);
    background-color: rgb(251, 253, 255);
    border-radius: 1rem;
    width: auto;
    /* padding: 2rem 2rem; */
    /* margin: 2rem 1rem; */



}
.Section3{

    display: block;

}
.Section4{

    margin: 0;
    border-radius: 0;

}

.Section4 .Logos1{

    width: 40%;
    height: auto;
    padding: 0rem;


}

.Head3{
    font-size: 2rem;
    padding: 20% 10%;

}


.Section5{
    display: block;
    margin: .5rem;
    padding: .5rem;
}

.DestGrid1{
    display: block;

}

.Dest{
    width: auto;
    min-height: 0;
    padding: 4rem;

}

.ColmSec3{

    width: 90%;
    margin-left:auto ;
    margin-right: auto;
}
.ExTitle{
    padding: .4rem .4rem;

}
.ColmSec5{

    width: auto;
    margin: 3rem 0;
}

.Text5a{
    text-align: center;
    margin: 1rem 0;

}

.Flag1{
    
    /* position: absolute; */
    justify-content: center;
    /* margin-left: auto; */
    /* margin-right: auto; */
    width: 100%;
    margin: 1rem 0rem;
    display: flex;
    justify-content: center;




}
.Flag1 img{
    width: 3rem;
    /* width: 100%; */
}


.GridTeam1{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
    
.FooterCont{
    margin-left: 0rem;
    text-align: center;
    padding: 0 20% ;
}


.Head1{

    font-size: 1rem;
    padding-bottom: 0;
    margin-bottom: 0rem;
    /* margin-top: 2rem; */
}
.Subhead1{

    font-size: 1.2rem;;
}


.SubheadSec3{

    font-size: 1.6rem;
    font-weight: 700;
    margin: 2rem  0 1rem 0rem;
    text-align: center;
    min-height: 1rem;
    letter-spacing: 2px;
}

}










.Link{

    text-decoration: none;
    color: white;
}

.Link:hover{

    text-decoration: none;
    color: black;
}