
/* *********************************** */


@media screen and (min-width:45rem) {

    .BgRespPg{
        
        background-color: rgb(252, 252, 252);
        width: auto;
        height: 632px;
        /* display: flex; */
        background-image: url('./Images/BgBeRespBanner2.png');
        background-repeat:no-repeat;
        background-size: contain;
    
    }
    
    
    .HeadRespPg{
    
        font-family: 'Philosopher';
        font-size: 30px;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        padding-top: 300px;
        color: #D1181F;
        
    
    }
    
    .SubHeadRespPg{
        font-family: 'Philosopher';
        font-size: 1rem;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5rem;
        padding-top: .1rem;
        color: #D1181F;
    
    }
    
    
    
    
    .SecRespIntro{
        border: 1.5px #D1181F solid;
        border-radius: 2rem;
        height: 532px;
        margin: 3rem;
        margin-top: -7rem;
        background-color: white;
        opacity: 0.9;
        display: flex;
    
    }
    
    .SecRespIntro .TextDiv{
        width: 50%;
        
    }
    
    .TextRespBanner{
    
        font-family: 'Philosopher', sans-serif;
        font-size: 1rem;
        font-weight: 800;
        color:rgb(51, 51, 51);
        width: 25rem;
        margin: 3rem;
        text-align: ;
        line-height: 1.6rem;
        margin-top: 6rem;
    }
    
    .ImgLogoResp1{
        /* width: ; */
        /* height: ; */
    }
    
    
    .ImgLogoResp2{
    
        width:10rem;
        height:10rem;
        margin-top:-2rem;
    }
    
    
    }


/* *********************************** */


@media screen and (max-width:45rem) {

.BgRespPg{
    
    background-color: rgb(252, 252, 252);
    width: auto;
    height: 632px;
    /* display: flex; */
    background-image: url('./Images/BgBeRespBanner2.png');
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;

}


.HeadRespPg{

    font-family: 'Philosopher';
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        line-height: auto;
        padding-top: 300px;
        color: #D1181F;
        width: 70%;
    

}

.SubHeadRespPg{

        font-family: 'Philosopher';
        font-size: 1rem;
        font-weight: 600;
        margin: 0 auto;
        margin-top:.6rem;
        text-align: center;
        line-height: 1.5rem;
        padding-top: .1rem;
        color: #D1181F;
}




.SecRespIntro{
    border: 1.5px #D1181F solid;
        border-radius: 2rem;
        height: auto;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        /* margin: .4rem; */
        margin-top: -7rem;
        margin-bottom: 2rem;
        background-color: white;
        opacity: 0.9;
        display: block;

}

.SecRespIntro .TextDiv{
    width: 90%;
    
}

.TextRespBanner{

        font-family: 'Philosopher', sans-serif;
        font-size: .8rem;
        font-weight: 300;
        color:rgb(51, 51, 51);
        width: 100%;
        margin: 1rem;
        text-align: ;
        line-height: 1.4rem;
        margin-top: 6rem;

}

.ImgLogoResp1{
    width: 100%;
    margin-bottom: -5px;
    /* height: ; */
}


.ImgLogoResp2{

    width: 40%;
    height: 50%;
    margin-top: ;
    margin-left: 0rem;
    margin-bottom:1rem;
    /* margin-left: 5rem;   */
}


}