
@media screen and (min-width: 62.5rem) {

    .aura-logo{
    
        margin-left:10rem;
        width:140px;
        margin-top:25px;
        position:fixed;
    
    }
    
    }


@media screen and (max-width: 62.5rem) {

.aura-logo{

    margin-left: 2rem;
    width:140px;
    margin-top:25px;
    position:fixed;

}

}

@media screen and (max-width: 45rem) {
    .aura-logo{

        margin-left:2rem ;
        width:140px;
        margin-top:25px;
        position:fixed;
    
    }
    


}
