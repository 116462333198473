@media screen and (max-width:45rem){  


.headingHome{

    font-size: 1.4rem;
    font-weight:200;
    text-align: center;
    padding-top: 30rem;
    width: 80%;
    line-height: 2rem;
    align-content: center;

    font-family: 'Philosopher';
    /* font-weight: 300; */
    margin: 0 auto;
    
    color: #e93f45;
    
}

.backgHomeBanner{

    /* background-color: rgb(252, 252, 252); */
    background-color: rgb(244, 244, 244);
    width: 100%;
    height: 38rem;
    /* display: flex; */
    /* position: ; */
    background-image: url('./Images/HomePageBanner.png');
    /* background-position: center; */
    background-size: cover;

}

.backgHomeBanner .Img001{
   
    /* width: 100%; */
    

}

.backgHomeBanner img {
    /* width:auto; */
    height:100%;
    /* position: fixed; */

}


.BgExpSecHome{

    background-image: url('./Images/TribalKutch-sambit-nayak-3v3nJumVn0A-unsplash.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    background-color: #d1181f;
    background-blend-mode: overlay;

}



}



/* ********************* */

@media screen and (min-width:45rem){


.headingHome{

    font-size: 30px;
    font-weight: 200;
    text-align: center;
    
    padding-top: 20rem;
    align-content: center;

    font-family: 'Philosopher';
    font-weight: 300;
    /* margin: 0 auto; */
    line-height: 40px;
    color: #d1181f;
    
}

.backgHomeBanner{
    /* background-color: rgb(252, 252, 252); */
    background-color: white;
    width: 100%;
    height: 732px;
    /* display: flex; */
    /* position: ; */
    background-image: url('./Images/HomePageBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.backgHomeBanner img {
    width:100%;
    /* position: fixed; */

}


.BgExpSecHome{

    background-image: url('./Images/TribalKutch-sambit-nayak-3v3nJumVn0A-unsplash.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    background-color: #d1181f;
    background-blend-mode: overlay;

} 


    
}






.widthFaIcon{
    padding-left: 10px;
    padding-right:10px;
}



.BgExpSecHome:hover {

    background-color: #be1218;
    background-blend-mode: overlay;

}
