@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.home,
.about,
.contact, 
.login, 
.team {

    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: center;
    font-size: 3rem;    
}


* {

  box-sizing: border-box;
  margin:0;
  padding: 0;

  /* font-family: 'Open Sans', sans-serif;     */

}



