/* for devices 1000px and above, visually tested till 1440px */

@media screen and (min-width: 62.5rem) {

    .navbar-top-container{
        display: flex;
        z-index: 99999;
    }
    
    .navbar-top-container a{
        padding: 10px 10px;
        text-decoration: none;
        color: rgb(32, 32, 32);
    }
    
    ul{
        list-style-type: none;
    }

    .Navbar{
        
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        top:0;
        position: fixed;
        z-index: 99999;
        
    }
    
    .menu-bars{
        margin-left: 2rem;
        font-size: 24px;
        top:42px;
        right:40px;
        position: fixed;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;  
    }
    
    .nav-menu{
    
        z-index: 999999;
        font-family: ' Open-Sans', sans-serif ;
        background-color: grey;
        width: 250px;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        right:-100%;
        transition: 850ms;
        
        /* border-left: 2px solid darkgrey; */
        letter-spacing: .025rem;
        text-transform: uppercase;
    
    }
    
    .nav-menu.active {
        right:0;
        transition: 350ms;
        z-index: 999999;
    }
    
    .nav-menu.active .navbar-toggle{
       visibility: visible;
       transition:ease-in;
       transition-delay: 400ms;
       color: white !important;
       right:200px;
       z-index: 999999;
       
    }
    
    .nav-text{
        
        display:flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 0px 4px 4px;
        list-style: none;
        height: 40px;
    }
    
    .nav-text a {
        text-decoration: none ;
        color: #f5f5f5;
        font-size: 0.7rem;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 2px;
        border-radius: 4px;    
    }
    
    .nav-text a:hover{ 
        /* background-color:#00276F ; */
        background-color:darkred ;
        transition: 2s;
    }
    
    .nav-menu-items{
        width:100%;
    }
    
    .navbar-toggle{
    
        z-index: 99999;
        background-color:grey;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        visibility: hidden;
    }
    
    .toggle-view{
        position: relative ;
        left:150px;
        
    }
    
    .arrow-right-custom{
        top:18px;
        color: darkred;
    }
    .menu-bar-icon-custom{
        color: rgb(177, 24, 24);
        /* transform: rotate(90deg); */
    }
    
    span{
        margin-left: 30px;    
    }
    
    .nav-signature{
    
        font-size: 9px;
        font-family: 'Open Sans', sans serif ;
        font-weight: 400;
        color:white;
        position:fixed;
        bottom:20px;
        /* left:20px; */
        margin-left: -75px;
    }
    
    /* Need to make this style for all devices */
    .navbar-top{     

        z-index: 99999;

        /* font-family: 'Philosopher', sans-serif; */
        font-family: 'Open-sans', sans-serif;
        font-size: .7rem;
        font-weight:200;
        color: rgb(36, 36, 36);
        position: fixed;
        top:50px;
        left: 20rem;
        letter-spacing: .025rem;
        text-transform: uppercase;
    
        /* border-radius: 1px grey; */
        
       
    }


}

/* **************************** devices: 720px - 1000px */

@media screen and (max-width: 62.5rem) {

.navbar-top-container{
    display: flex;
    z-index: 99999;
}

.navbar-top-container a{
    padding: 10px 8px;
    text-decoration: none;
    color: rgb(32, 32, 32);
   
}

ul{
    list-style-type: none;
}

.Navbar{
    
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top:0;
    position: fixed;
    z-index: 99999;
    
}

.menu-bars{
    margin-left: 2rem;
    font-size: 24px;
    top:42px;
    right:40px;
    position: fixed;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;  
}

.nav-menu{

    z-index: 999999;
    font-family: ' Open-Sans', sans-serif ;
    background-color: grey;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right:-100%;
    transition: 850ms;
    
    /* border-left: 2px solid darkgrey; */
    letter-spacing: .025rem;
    text-transform: uppercase;

}

.nav-menu.active {
    right:0;
    transition: 350ms;
    z-index: 999999;
}

.nav-menu.active .navbar-toggle{
   visibility: visible;
   transition:ease-in;
   transition-delay: 400ms;
   color: white !important;
   right:200px;
   z-index: 999999;
   
}

.nav-text{
    
    display:flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 0px 4px 4px;
    list-style: none;
    height: 40px;
}

.nav-text a {
    text-decoration: none ;
    color: #f5f5f5;
    font-size: 0.7rem;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;    
}

.nav-text a:hover{ 
    /* background-color:#00276F ; */
    background-color:darkred ;
    transition: 2s;
}

.nav-menu-items{
    width:100%;
}

.navbar-toggle{

    z-index: 99999;
    background-color:grey;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    visibility: hidden;
}

.toggle-view{
    position: relative ;
    left:150px;
    
}

.arrow-right-custom{
    top:18px;
    color: darkred;
}
.menu-bar-icon-custom{
    color: rgb(177, 24, 24);
    /* transform: rotate(90deg); */
}

span{
    margin-left: 30px;    
}

.nav-signature{

    font-size: 9px;
    font-family: 'Open Sans', sans serif ;
    font-weight: 400;
    color:white;
    position:fixed;
    bottom:20px;
    /* left:20px; */
    margin-left: -75px;
}


.navbar-top{     
    z-index: 99999;

    /* font-family: 'Philosopher', sans-serif; */
    font-family: 'Open-sans', sans-serif;
    font-size: .7rem;
    font-weight:200;
    color: rgb(36, 36, 36);
    position: fixed;
    top:50px;
    left: 12rem;
    letter-spacing: .025rem;
    text-transform: uppercase;
    /* border-radius: 1px grey; */

}


    
}

/* 880px - 1000px */


@media screen and (max-width: 880px ) {


    .navbar-top{     
        visibility: hidden;
        z-index: 99999;
    
        /* font-family: 'Philosopher', sans-serif; */
        font-family: 'Open-sans', sans-serif;
        font-size: .7rem;
        font-weight:200;
        color: rgb(36, 36, 36);
        position: fixed;
        top:50px;
        left: 12rem;
        letter-spacing: .025rem;
        text-transform: uppercase;
        /* border-radius: 1px grey; */
    
    }

    



}
/* *************************** Devices: till 45rem  */

@media screen and (max-width: 45rem) {

    .navbar-top-container{
        display: flex;
        z-index: 99999;
    }
    
    .navbar-top-container a{
        padding: 10px 12px;
        text-decoration: none;
        color: rgb(32, 32, 32);
       
    }
    
    ul{
        list-style-type: none;
    }
    
    .Navbar{
        
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        top:0;
        position: fixed;
        z-index: 99999;
        
    }
    
    .menu-bars{
        margin-left: 2rem;
        font-size: 24px;
        top:42px;
        right:40px;
        position: fixed;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;  
    }
    
    .nav-menu{
    
        z-index: 999999;
        font-family: ' Open-Sans', sans-serif ;
        background-color: grey;
        width: 250px;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        right:-100%;
        transition: 850ms;
        
        /* border-left: 2px solid darkgrey; */
        letter-spacing: .025rem;
        text-transform: uppercase;
    
    }
    
    .nav-menu.active {
        right:0;
        transition: 350ms;
        z-index: 999999;
    }
    
    .nav-menu.active .navbar-toggle{
       visibility: visible;
       transition:ease-in;
       transition-delay: 400ms;
       color: white !important;
       right:200px;
       z-index: 999999;
       
    }
    
    .nav-text{
        
        display:flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 0px 4px 4px;
        list-style: none;
        height: 40px;
    }
    
    .nav-text a {
        text-decoration: none ;
        color: #f5f5f5;
        font-size: 0.7rem;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 2px;
        border-radius: 4px;    
    }
    
    .nav-text a:hover{ 
        /* background-color:#00276F ; */
        background-color:darkred ;
        transition: 2s;
    }
    
    .nav-menu-items{
        width:100%;
    }
    
    .navbar-toggle{
    
        z-index: 99999;
        background-color:grey;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        visibility: hidden;
    }
    
    .toggle-view{
        position: relative ;
        left:150px;
        
    }
    
    .arrow-right-custom{
        top:18px;
        color: darkred;
    }
    .menu-bar-icon-custom{
        color: rgb(177, 24, 24);
        /* transform: rotate(90deg); */
    }
    
    span{
        margin-left: 30px;    
    }
    
    .nav-signature{
    
        font-size: 9px;
        font-family: 'Open Sans', sans serif ;
        font-weight: 400;
        color:white;
        position:fixed;
        bottom:20px;
        /* left:20px; */
        margin-left: -75px;
    }
    
    
    .navbar-top{     
        visibility: hidden;
        /* z-index: 99999; */
    
        /* font-family: 'Philosopher', sans-serif; */
        font-family: 'Open-sans', sans-serif;
        font-size: .7rem;
        font-weight:200;
        color: rgb(36, 36, 36);
        position: fixed;
        top:50px;
        left: 360px;
        letter-spacing: .025rem;
        text-transform: uppercase;
        /* border-radius: 1px grey; */
    
    }

    



}


