@media screen and (min-width:72rem) {
    

.HeadContactPg{

    font-family: 'Philosopher';
    font-size: 2rem;
    font-weight: 300;
    margin: 0 auto;
    line-height: 40px;
    padding-top: 240px;
    color: #D1181F;
    text-align: center;

}


.HeadContactPg a{

    /* text-decoration: none;
    text-align: center;
    font-size: 1rem; */
}

.HeadContactPg p{

    /* text-align: center;
    font-size: 1rem;
    line-height:1.6rem ; */

}


.ContactPgCont{
    
    /* background-color: rgb(252, 252, 252);
    width: 60%;
    min-height: 780px; */
    /* display: flex; */

}

.ContactBg{

    background-color: rgb(252, 252, 252);
    width: 100%;
    min-height: 780px;

    background-color: rgb(252, 252, 252);
    /* height: 100%; */
    display: flex;
    justify-content: center;
}

.CardDisp{

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.CardDisp a{
    text-decoration: none;
    font-family: 'Philosopher';
    /* color: #D1181F; */
    color: #a20d13;
}

.AddressCard{

    background-color: white;
    width: 20rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 1.5rem;
    margin:.5rem;

    text-align: left;
    font-size:.8rem;
    line-height: 1.4rem;
    /* width: 80%; */
    /* margin: 0 auto; */

    font-family: 'Philosopher';
    /* color: #D1181F; */
    color: #a20d13;
  

}
.AddressCard:hover {
    background-color: rgb(251, 251, 251);
    border-color:rgb(240, 239, 239);
}

.ContactCard{
    width: 16rem;
    border-radius: 1rem;
    border: 1px solid grey;
    margin: 1.6rem;

    background-color: white;
    
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 1.5rem;
    margin: 1rem;

    text-align: left;
    font-size:.8rem;
    line-height: 1.4rem;

    font-family: 'Philosopher';
    /* color: #D1181F; */
    color: #a20d13;
}   

.ContactCard:hover {

    background-color: rgb(251, 251, 251);
    border-color:rgb(240, 239, 239);
}


}



@media screen and (max-width:72rem) {
    

    .HeadContactPg{
    
        font-family: 'Philosopher';
        font-size: 1.4rem;
        font-weight: 300;
        margin: 0 auto;
        line-height: 40px;
        padding-top: 240px;
        color: #D1181F;
        text-align: center;
    
    
    }

    .HeadContactPg a{

        text-decoration: none;
        text-align: center;
        font-size: .8rem;
    }

    .HeadContactPg p{
        /* text-align: center;
        font-size:.8rem;
        line-height: 1.5rem;
        width: 80%;
        margin: 0 auto; */

    
    }



    .ContactPgCont{
        
        
       /* display: flex; */
       /* flex-wrap: wrap; */
       /* justify-content: flex-start; */

    
    }

    .ContactBg{
        background-color: rgb(252, 252, 252);
        width: 100%;
        min-height: 780px;

        background-color: rgb(252, 252, 252);
         width: 100%;
         display: flex;
         justify-content: center;
    }
    
    .CardDisp{

        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    
    }

    .CardDisp a{
        text-decoration: none;
        font-family: 'Philosopher';
        /* color: #D1181F; */
        color: #a20d13;
    }



    .AddressCard{
        
        background-color: white;
        width: 12rem;
        border-radius: 1rem;
        border: 1px solid grey;
        margin: .5rem;

        /* text-align: center; */
        font-size:.6rem;
        line-height: 1.5rem;
        /* width: 80%; */
        /* margin: 0 auto; */

        font-family: 'Philosopher';
        
        font-weight: 300;
        margin: 0 auto;
        
        
        /* color: #D1181F; */
        color: #a20d13;
        text-align: center;
    

    }
    
    .ContactCard{
        width: 14rem;
        border-radius: 1rem;
        border: 1px solid grey;
        margin: .5rem;
    }  
    


.AddressCard{

    background-color: white;
    width: 20rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 1.5rem;
    margin: .5rem;

    text-align: left;
    font-size:.8rem;
    line-height: 1.4rem;
    /* width: 80%; */
    /* margin: 0 auto; */

}
.AddressCard:hover {
    background-color: rgb(251, 251, 251);
    border-color:rgb(240, 239, 239);
}

.ContactCard{
    width: 14rem;
    border-radius: 1rem;
    border: 1px solid grey;
    margin: 1.6rem;

    background-color: white;
    
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 1.5rem;
    margin: .5rem;

    text-align: left;
    font-size:.8rem;
    line-height: 1.4rem;

    font-family: 'Philosopher';
    /* color: #D1181F; */
    color: #a20d13;
}   

.ContactCard:hover {

    background-color: rgb(251, 251, 251);
    border-color:rgb(240, 239, 239);

}


    
}