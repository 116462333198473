.HeadChatPg{

    font-family: 'Philosopher';
    font-size: 30px;
    font-weight: 300;
    margin: 0 auto;
    line-height: 40px;
    padding-top: 240px;
    color: #D1181F;
    

}

.BgChatPg{
    
    background-color: rgb(252, 252, 252);
    width: auto;
    height: 512px;
    display: flex;

}