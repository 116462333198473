.footer-container{

    display:flex;

}

.footer-app {
    
    align-content: center;
    text-decoration: none;
    font-size: 10px;
    /* padding-left: 20px; */
    padding-bottom:10px;
    padding-left: 10px;
    margin-left:auto;
    margin-right: auto;

}
.footer-app ul {
    display: flex;
}
.footer-app li{

    padding: 10px;
    color: grey;
}    

.footer-container a {
    text-decoration: none;
    color: grey;
}

