@media screen and (min-width:45rem){


.HeadExperiencesPg{

    font-family: 'Philosopher';
    font-size: 30px;
    font-weight: 300;
    /* margin: 0 auto; */
    line-height: 40px;
    padding-top: 240px;
    color: #D1181F;
    /* margin-left: auto; */
    /* margin-right: auto; */
    text-align: center;
    /* padding-bottom: 4rem; */

}

.SubHeadExperiencesPg{

    font-family: 'Philosopher';
    font-size: .8rem;
    font-weight: 300;
    width: 40%;
    /* margin: 0 auto; */
    line-height: 1.2rem;
    padding-top: 1rem;
    color: #D1181F;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 4rem;

}

.BgExperiencesPg{
    
    background-color: rgb(252, 252, 252);
    width: auto;
    height: auto;
    /* display: flex; */
    padding-bottom: 4rem;

}

.HeadExpSecHome{
   
    border-radius: 8px;
    text-align: center;
    font-family: 'Philosopher';
    font-weight: 600;
    font-size:22px ;
    padding-top: 300px;
    /* color: #D1181F; */
    color: white;
    
}

.BgExpSecHome{
    background-color: azure;
    
    height: 632px;
}


.BtExpAll{

    font-family: 'Philosopher';
    font-weight: 800;
    /* border: 1.5px solid rgb(218, 38, 38); */
    border-radius: 8px;
    padding: 10px;
    max-width: 240px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    /* color: #D1181F; */
    color: white;
    text-decoration: none;
    
}

.HeadExpSecHome a{
    text-decoration: none;
}

.BgExpSecHome a{
    text-decoration: none;
}


.BtExpAll:hover{

    /* border: 1.5px solid red; */
    padding: 10px;
    max-width: 240px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    /* color: red ; */
    color: black;
    
}

.BtExpAll svg{

    color: white;
}

.BtExpAll:hover svg{

    color: black;
}

.widthFaIconExpAll{

    color: white;

}

.ExpPgCard .FaIconCirc{

    /* color: darkred; */
    color: transparent;
    /* color: rgb(242, 241, 241); */
    /* position: absolute; */
    position: relative;
    /* padding-top:1rem; */
    /* padding-left: 0; */
    /* bottom: 0rem; */
    /* right: 0rem; */
    /* left: 0rem; */
    /* top: 0rem; */

}

.ExpPgCard:hover .FaIconCirc{

    /* color: rgb(177, 1, 1); */

}


.widthFaIconExpAll{
    padding-left: 10px;
    padding-right: 0px;
}

.ExpPgExCont{

    display: flex;
    flex-wrap: wrap;
    /* width: ; */
    padding-left: 1rem;
    padding-right: 1rem;
    /* margin-left: auto; */
    /* margin-right: auto; */
    align-items: space-between;
    align-content: center;
    justify-content: center;

}

.ExpPgCard{

    min-width: 24rem;
    /* max-width: 14rem; */
    /* height: 16rem; */
    height:auto;
    border: 1px solid rgb(221, 221, 221);
    background-color: white;
    border-radius: 1rem;
    padding: 1.4rem;
    margin:.5rem;
}


.ExpPgCard:hover {

    /* background: rgb(231, 231, 231); */
    /* border: 1px solid red ; */
    border: 1px solid rgb(177, 1, 1);

}

.ExpPgHExpName{
    font-family: 'Philosopher', serif ;
    font-size:.9rem ;
    font-weight: 300;
    color: darkred;
    /* position: absolute; */

}

.ExpPgHCat{
    font-family: 'Philosopher', serif ;
    font-size:.7rem ;
    font-weight: 300;
    
    padding-top: .5rem;
    color: darkred;
    /* position: absolute; */
}

.ExpPgHCode{
    /* font-family: 'Philosopher', serif ; */
    font-size:.5rem ;
    font-weight: 400;
    letter-spacing: .1rem;
    padding-top: .5rem;
    color: rgb(164, 164, 164);
    /* position: absolute; */

}

.IconsAuraExpDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.IconsAuraExp{
    width: 200px;
    /* width:50%; */

    /* height: 200px; */
    margin-left: auto;
    margin-right: auto;
}

.IconsAuraExp3{
    width: 50%;
    margin-left: 20%;
    margin-right: 20%;
}

.ExpBoxDet{
    /* border:1px solid #D1181F; */
    border:1px solid rgb(224, 224, 224);
    
    border-radius: 1rem;
    width:80%;
    height:auto;
    /* margin-top: 20rem; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;

}
.spacer{
    height: 10rem;

}
.ExpTitle{

    font-family: 'Philosopher', sans-serif;
    font-size: 1.4rem;
    position:relative;
    left:0;
    padding-top:2rem;
    padding-left:2rem;
    color:rgb(177, 1, 1);

}

.ExpCat{

    font-family: 'Philosopher', sans-serif;
    font-size:1rem;
    color:#D1181F;
    padding-top: 1rem;
    padding-left: 2rem;

}

.ExpPgDetBan1 {
    width: auto;
    background-color: rgb(241, 241, 241);
    height: 20rem;
    margin:2rem;
    /* background-image: url(''); */
    border-radius: 1rem;
    opacity: .2;
}

.FaIconCircExpDet{

    color:#D1181F;
    position:relative;
    /* position: fixed; */
    top: 1.5rem;
    /* left: 2rem; */
    float: right;
    padding-right:2rem;
    /* margin-top:-4rem; */

}

.sec{

    height:16rem;
    
}

.ExpAvailCardsCont{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding:.8rem 2rem;

}

.ExpAvailCard{
    
    background-color: rgb(241, 240, 240);
    height: 8rem;
    width: 10rem;
    border-radius: .8rem;
    margin-right: .6rem;

}

.ExpAvailCard:hover .ActivityPlusBt{
    color:rgb(177, 1, 1);

}

.PlanSubhead{

    font-family: 'Philosopher', sans-serif;
    font-size: .8rem;
    line-height: 1.2rem;
    margin-left: 2rem;
    margin-top: 1rem;
    width: 40%;

}

.CuratorName{

    font-size: .8rem;
    font-family: 'Philosopher', sans-serif;
    margin-top: 9rem;
    text-align: center;
    color: grey;

}

.ActivityName{

    font-size: .8rem;
    font-family: 'Philosopher', sans-serif;
    margin-top: 9rem;
    text-align: center;
    color: rgb(66, 66, 66);

}
.ActivityPlusBt{
    font-size: 1.3rem;
    /* color: rgb(211, 211, 211); */
    color: white;
    padding: .6rem;
    /* position:absolute; */
    /* right:.6rem; */
    float: right;

}

   


    
}



/* ******************************** */

@media screen and (max-width:45rem){


    .HeadExperiencesPg{

        font-family: 'Philosopher';
        font-size: 1.2rem;
        font-weight: 300;
        width: 80%;
        margin: 0 auto;
        line-height: auto;
        padding-top: 12rem;
        color: #D1181F;
        /* margin-left: auto; */
        /* margin-right: auto; */
        text-align: center;
        /* padding-bottom: 4rem; */
    
    }
    
    .SubHeadExperiencesPg{

        font-family: 'Philosopher';
        font-size: .7rem;
        font-weight: 300;
        width: 80%;
        /* margin: 0 auto; */
        line-height: 1rem;
        padding-top: 1rem;
        color: #D1181F;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding-bottom: 4rem;
    
    }

    .BgExperiencesPg{
        
        background-color: rgb(252, 252, 252);
        width: auto;
        height: auto;
        /* display: flex; */
        padding-bottom: 1.5rem;
    
    }
    
    .HeadExpSecHome{
       
        border-radius: 8px;
        text-align: center;
        font-family: 'Philosopher';
        font-weight: 300;
        font-size: 1.4rem ;
        padding-top: 10rem;
        /* color: #D1181F; */
        color: white;
        width: 90%;
        margin: 0 auto;
        
    }
    
    .BgExpSecHome{
        background-color: azure;
        
        height: 30rem;

    }
    
    
    .BtExpAll{
    
        font-family: 'Philosopher';
        font-weight: 800;
        /* border: 1.5px solid rgb(218, 38, 38); */
        border-radius: 8px;
        padding: 10px;
        max-width: 240px;
        text-align: center;
        margin: 0 auto;
        margin-top: 30px;
        /* color: #D1181F; */
        color: white;
        text-decoration: none;
        
    }
    
    .HeadExpSecHome a{
        text-decoration: none;
    }
    
    .BgExpSecHome a{
        text-decoration: none;
    }
    
    
    .BtExpAll:hover{
    
        /* border: 1.5px solid red; */
        padding: 10px;
        max-width: 240px;
        text-align: center;
        margin: 0 auto;
        margin-top: 30px;
        /* color: red ; */
        color: black;
        
    }
    
    .BtExpAll svg{
    
        color: white;
    }
    
    .BtExpAll:hover svg{
    
        color: black;
    }
    
    .widthFaIconExpAll{
    
        color: white;
    
    }
    
    .ExpPgCard .FaIconCirc{
    
        /* color: darkred; */
        color: transparent;
        /* color: rgb(242, 241, 241); */
        /* position: absolute; */
        position: relative;
        /* padding-top:1rem; */
        /* padding-left: 0; */
        /* bottom: 0rem; */
        /* right: 0rem; */
        /* left: 0rem; */
        /* top: 0rem; */
    
    }
    
    .ExpPgCard:hover .FaIconCirc{
    
        /* color: rgb(177, 1, 1); */
    
    }
    
    
    .widthFaIconExpAll{
        padding-left: 10px;
        padding-right: 0px;
    }
    
    .ExpPgExCont{
    
        display: flex;
        flex-wrap: wrap;
        /* width: ; */
        /* padding-left: 1rem; */
        /* padding-right: 1rem; */
        /* margin-left: auto; */
        /* margin-right: auto; */
        align-items: space-between;
        align-content: center;
        justify-content: center;
    
    }
    
    .ExpPgCard{
    
        width: 20rem;
        /* max-width: 14rem; */
        /* height: 14rem; */
        height: auto;
        border: 1px solid rgb(221, 221, 221);
        background-color: white;
        border-radius: 1rem;
        padding: 1.4rem;
        margin:.5rem;
    }
    
    
    .ExpPgCard:hover {
    
        /* background: rgb(231, 231, 231); */
        /* border: 1px solid red ; */
        border: 1px solid rgb(177, 1, 1);
    
    }
    
    .ExpPgHExpName{
        font-family: 'Philosopher', serif ;
        font-size:.9rem;
        font-weight: 300;
        color: darkred;
        /* position: absolute; */
    
    }
    
    .ExpPgHCat{
        font-family: 'Philosopher', serif ;
        font-size:.7rem ;
        font-weight: 300;
        
        padding-top: .5rem;
        color: darkred;
        /* position: absolute; */
    }

    .ExpPgHCode{
        /* font-family: 'Philosopher', serif ; */
        font-size:.5rem ;
        font-weight: 300;
        
        padding-top: .5rem;
        color: rgb(164, 164, 164);
        /* position: absolute; */
    
    }

    .IconsAuraExpDiv{
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    
    .IconsAuraExp{
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        
    }
    
    .IconsAuraExp3{
        width: 50%;
        margin-left: 20%;
        margin-right: 20%;
    }

    
    .ExpBoxDet{
        /* border:1px solid #D1181F; */
        border:1px solid rgb(224, 224, 224);
        
        border-radius: 1rem;
        width:96%;
        height:auto;
        /* margin-top: 20rem; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
    
    }
    .spacer{
        height: 8rem;
    
    }
    .ExpTitle{
    
        font-family: 'Philosopher', sans-serif;
        font-size: 1rem;
        line-height: 1.4rem;
        position:relative;
        left:0;
        width: 70%;
        padding-top:1.2rem;
        padding-left:1.2rem;
        color:rgb(177, 1, 1);
    
    }
    
    .ExpCat{
    
        font-family: 'Philosopher', sans-serif;
        font-size:.8rem;
        color:#D1181F;
        padding-top: .5rem;
        padding-left: 1.2rem;
    
    }
    
    .ExpPgDetBan1 {
        width: auto;
        background-color: rgb(241, 241, 241);
        height: 20rem;
        margin:.8rem;
        /* background-image: url(''); */
        border-radius: 1rem;
        opacity: .2;
    }
    
    .FaIconCircExpDet{
    
        color:#D1181F;
        position: relative;
        /* position: fixed; */
        top: 1rem;
        /* left: 2rem; */
        float: right;
        padding-right:1rem;
        /* margin-top:-2rem; */
    
    }
    
    .sec{
    
        height:auto;
        display: flex;
        flex-wrap: wrap;
        padding-top: .6rem;
        
    }


    .sec-notes{
    
        height:auto;
        display: block;
        /* flex-wrap: wrap; */
        padding-top: .6rem;
        padding-bottom: 1rem;
        
    }
    
    .ExpAvailCardsCont{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding:.8rem .6rem;
        width:100%;
    
    }
    
    .ExpAvailCard{
        
        background-color: rgb(241, 240, 240);
        height: auto;
        width: 45%;
        border-radius: .8rem;
        margin-right: .6rem;
        margin-top: .4rem;
    
    }
    
    .ExpAvailCard:hover .ActivityPlusBt{
        color:rgb(177, 1, 1);
    
    }
    
    .PlanSubhead{
    
        font-family: 'Philosopher', sans-serif;
        color: grey;
        font-size: .6rem;
        line-height: .8rem;
        margin-left: 1rem;
        margin-top: 1rem;
        width: 40%;
    
    }

    .PlanSubhead{
    
        font-family: 'Philosopher', sans-serif;
        color: grey;
        font-size: .6rem;
        line-height: .8rem;
        margin-left: 1rem;
        margin-top: 1rem;
        width: 80%;
    
    }
    
    .CuratorName{
    
        font-size: .6rem;
        font-family: 'Philosopher', sans-serif;
        margin-top: 9rem;
        text-align: center;
        color: grey;
        padding-bottom: .4rem;
    
    }
    
    .ActivityName{
    
        font-size: .6rem;
        font-family: 'Philosopher', sans-serif;
        margin-top: 9rem;
        text-align: center;
        color: rgb(66, 66, 66);
        padding-bottom: .4rem;
    
    }

    .ActivityPlusBt{
        font-size: 1.3rem;
        /* color: rgb(211, 211, 211); */
        color: white;
        padding: .6rem;
        /* position:absolute; */
        /* right:.6rem; */
        float: right;
    
    
    }

    .Scale20{
        width:;

    }
    



}
