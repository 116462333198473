@media screen and (min-width:45rem) {


    .HeadAboutPg{

        font-family: 'Philosopher';
        font-size: 30px;
        font-weight: 600;
        color: #D1181F;
        margin: 0 auto;
        line-height: 40px;
        padding-top: 300px;
        color: #D1181F;
        text-align: center;
    
    }


.AboutPgSubH{  

    font-size: 1rem;
    font-family: 'Philosopher';
    color:#D1181F; 
    text-align: center;
    padding: 1rem;

}

.BgAboutPg{
    
    /* background-color: rgb(252, 252, 252); */
    width: auto;
    height: 632px;
    
    background-image: url('./Images/BgImgAbout.png');
    background-repeat: no-repeat;
    background-size: cover;
    

}

.BgSecIntro{

    background-color: #D1181F;
    height: 632px;
    display: flex;

}

.BgSecIntroH1{
    font-family: 'Philosopher';
    font-size: 1rem;
    font-weight: 300;
    color: white;
    margin-top: 10rem;
    width: 20%;
    padding:0 2rem;
    line-height: 1.5rem;

}

.BgSecIntroH2{
    font-family: 'Philosopher';
    font-size: 2rem;
    font-weight: 300;
    color: white;
    line-height: 2.5rem;

    margin-top:14rem;
    width: 30%;
}


.ImgCompass{
    width: 40rem;
    height:40rem;
}

.BgSecLogo{
    display: flex;
    height: 632px;
    width: 100%;

}

.ImgBindi{
    width: 20rem;
    height:20rem;
    margin-left: 20%;
    margin-top: 10rem;
}

.LogoSecH{
    font-family: 'Philosopher';
    font-size: 2rem;
    font-weight: 300;
    color:#D1181F;
    line-height: 2.5rem;

    margin-top:14rem;
    width: 30%;
    margin-left: 10%;

}

.BgSecLogoPattern{

    background-image: url('./Images/BgLogoSecPattern.png');

    width:100%;
    height: 632px;
    background-repeat: no-repeat;
    background-size: contain;

}

.LogoSecPatternH{
    font-family: 'Philosopher';
    font-size: 1rem;
    font-weight: 300;
    color:#D1181F;
    line-height: 1.7rem;

    /* margin-top:14rem; */
    width: 30%;
    /* margin-left: 10%; */
    background-color: white;
    padding: 2rem;
    /* margin: 1rem; */
    /* margin-top: 10rem; */
    position:absolute;
    right: 20%;

}

.SecAuraOffers{
    width: 100%;
    height: 632px;
    
}
.SecAuraOffersH1{

    font-family: 'Philosopher';
    font-size: 1.6rem;
    font-weight: 300;
    color:#D1181F;
    line-height: 1.7rem;
    text-align: center;
    padding-top:14rem;
    
    width: 30rem;
    margin: 0 auto;
    background-color: white;
    
}

.SecAuraOffersH2{

    font-family: 'Philosopher';
    font-size: 0.9rem;
    font-weight: 300;
    color: rgb(63, 63, 63);
    line-height: 1.3rem;
    text-align: center;
    
    width: 30rem;
    margin: 0 auto;
    
    background-color: white;
    
}

.Spacer1{
    height: 3rem;
}




}


/* ************************************** */


@media screen and (max-width:45rem) {

    .HeadAboutPg{

        font-family: 'Philosopher';
        font-size: 30px;
        font-weight: 600;
        color: #D1181F;
        margin: 0 auto;
        line-height: 40px;
        padding-top: 300px;
        color: #D1181F;
        text-align: center;
        width: 90%;
    
    }

    .BgAboutPg{
    
        /* background-color: rgb(252, 252, 252); */
        width: 100%;
        height: 632px;
        
        background-image: url('./Images/BgImgAbout.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    
    }




.AboutPgSubH{  

    font-size: 1rem;
    font-family: 'Philosopher';
    color:#D1181F; 
    text-align: center;
    padding: 1rem;
}





.BgSecIntro{

    background-color: #D1181F;
    /* height: 632px; */
    display: block;

}

.BgSecIntroH1{
    font-family: 'Philosopher';
    font-size: .8rem;
    font-weight: 300;
    color: white;
    margin-top: 1rem;
    width: 90%;
    padding:2rem 1rem;
    line-height: 1.2rem;

}

.BgSecIntroH2{
    font-family: 'Philosopher';
    font-size: 1.6rem;
    font-weight: 300;
    color: white;
    line-height: 2rem;
    margin-left:auto;
    margin-right: auto;
    /* margin-top:14rem; */
    width: 90%;
    padding-bottom: 2rem;
}


.ImgCompass{
    width: 100%;
    height:;
}

.BgSecLogo{
    display: block;
    height: 632px;
    width: 100%;

}

.ImgBindiDiv{
    display: flex;    
}


.ImgBindi{
    width: 60%;
    /* height:20rem; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
}

.LogoSecH{
    font-family: 'Philosopher';
    font-size: 1.4rem;
    font-weight: 300;
    color:#D1181F;
    line-height: auto;
    padding-bottom: 2rem;
    margin-top:4rem;
    margin-bottom: 1rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

}

.BgSecLogoPattern{

    background-image: url('./Images/BgLogoSecPattern.png');

    width:100%;
    height: 720px;
    background-repeat: no-repeat;
    background-size: cover;

}

.LogoSecPatternH{
    font-family: 'Philosopher';
    font-size: .9rem;
    font-weight: 300;
    color:#D1181F;
    line-height: 1.5rem;

    /* margin-top:14rem; */
    width: 80%;
    /* margin-left: 10%; */
    background-color: white;
    padding: 1.6rem;
    /* margin: 1rem; */
    margin-top: 4rem;
    /* position:absolute; */
    /* right: 20%; */
    margin-left: auto;
    margin-right: auto;
}

.SecAuraOffers{
    width: 100%;
    height: 600px;
    padding-bottom: 12rem;
    
}
.SecAuraOffersH1{

    font-family: 'Philosopher';
    font-size: 1.4rem;
    font-weight: 300;
    color:#D1181F;
    line-height: 1.7rem;
    text-align: center;
    padding-top:12rem;
    
    width: 80%;
    margin: 0 auto;
    background-color: white;
    
}

.SecAuraOffersH2{

    font-family: 'Philosopher';
    font-size: 0.9rem;
    font-weight: 300;
    color: rgb(63, 63, 63);
    line-height: 1.3rem;
    text-align: center;
    
    width: 80%;
    margin: 0 auto;
    padding-top:1rem;
    background-color: white;
    
}

.Spacer1{
    height: 3rem;
}





    
}



