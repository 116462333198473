@media screen and (min-width:45rem){



.BgEnquiryPg{
    
    /* background-color: rgb(252, 252, 252); */
    width: auto;
    height: 632px;
    /* display: flex; */
    background-image: url('./Images/BgEnquireBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* padding-top: -8rem; */
    /* margin-left:-4rem; */
    /* z-index: -99; */

}

.EnqFormWid1Div{
    
    width: 50%;
    float: right;

}

.EnqFormWid1{
    width: 25rem;
    border-radius: 2rem;
    border: 1px solid #D1181F;
    height: auto ;
    margin-top: -10rem;
    background-color: white;
    opacity: 0.85;
    
    right: 50%;
    /* position: relative; */
    /* left: 50%; */
    margin-bottom: 3rem;

    
}

.HeadFormDiv {

    margin-top: 2rem ;
    margin: 2rem;
    font-size: 1rem;
    
}

.EnquiryFormStyle{

    padding: .5rem 2rem;
    display: block;
    /* margin-bottom: 2.5rem; */

}

.EnquiryFormStyle .EnquiryLabel{
    font-size: .8rem;
    display: flex;
    color:rgb(107, 107, 107);
    /* flex-wrap: wrap; */
    /* height: 2.4rem; */
    line-height: 2.4rem;

}

.EnquiryFormStyle .EnquiryInputMsg{
    font-size: .8rem;
    color:rgb(107, 107, 107);
    line-height: 1.4rem;

}


.EnquiryFormStyle .EnquiryInput{
    height: 2.4rem;
        
}

.EnquiryFormStyle .EnquiryInputMsg{

    min-height:8rem;
    /* max-height: auto; */
        
}

.EnquiryInputMsgDiv{
    

}

.EnquiryFormStyle input, textarea{

    width:100%;
    margin: 0rem 0rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(75, 75, 75) ;
    /* height: 2.4rem; */
    border-radius: .6rem;
    /* border-color: #D1181F; */
    background-color: rgb(241, 241, 241);
    border: 1px solid rgb(233, 232, 232);
    padding: .5rem;
    
    right: 0;
    /* position: relative;  */
    
    /* position: relative; */
    margin-bottom: .3rem;
    
}


.EnquiryFormStyle  input:active{
    border: 1px solid rgb(136, 135, 135);

}

.ButtonEnquiry{
    
    margin-top: 2rem;
    margin-bottom: 3rem;
    background-color: #b90208;
    color: white;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: .6rem 1.5rem;
    border-radius: .6rem;

}

.ButtonEnquiry:hover {
    background-color: #8b0206;

}

 a{
    text-decoration: none;
    /* color: white; */
}




    
}


/* ****************************** */

@media screen and (max-width:45rem){





.BgEnquiryPg{
    
    /* background-color: rgb(252, 252, 252); */
    width: auto;
    height: 600px;
    /* display: flex; */
    background-image: url('./Images/BgEnquireBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    

    /* margin-left:-10rem; */
    /* z-index: -99; */

}


.HeadFormDiv {

    margin-top: 2rem ;
    margin: 1.4rem;
    font-size: 1rem;
    
}

.EnquiryFormStyle{

    padding: .5rem 2rem;
    display: block;
    /* margin-bottom: 2.5rem; */

}

.EnquiryFormStyle .EnquiryLabel{
    font-size: .8rem;
    display: flex;
    color:rgb(107, 107, 107);
    /* flex-wrap: wrap; */
    /* height: 2.4rem; */
    line-height: 2.4rem;
    
}


.EnquiryFormStyle  input:active{
    border: 1px solid rgb(136, 135, 135);

}


.EnquiryFormStyle .EnquiryInputMsg{
    font-size: .8rem;
    color:rgb(107, 107, 107);
    line-height: 1.4rem;

}


.EnquiryFormStyle .EnquiryInput{
    height: 2.4rem;
        
}

.EnquiryFormStyle .EnquiryInputMsg{

    min-height:8rem;
    /* max-height: auto; */
        
}

.EnquiryInputMsgDiv{
    

}

.EnquiryFormStyle input, textarea{

    width:100%;
    margin: 0rem 0rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(75, 75, 75) ;
    /* height: 2.4rem; */
    border-radius: .6rem;
    /* border-color: #D1181F; */
    background-color: rgb(241, 241, 241);
    border: 1px solid rgb(233, 232, 232);
    padding: .5rem;
    
    right: 0;
    /* position: relative;  */
    
    /* position: relative; */
    margin-bottom: .3rem;
    
}


.ButtonEnquiry{
    
    margin-top: 2rem;
    margin-bottom: 3rem;
    background-color: #b90208;
    color: white;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: .6rem 1.5rem;
    border-radius: .6rem;

}

.ButtonEnquiry:hover {
    background-color: #8b0206;

}

 a{
    text-decoration: none;
    /* color: white; */
}

.EnquiryFormStyle .EnquiryInput{

    height: 2.4rem;
        
}

.EnquiryFormStyle .EnquiryInputMsg{
    height: 10rem;
        
}


.EnquiryInputMsgDiv{
    


}


    
}

/* ****************************** */


@media screen and (min-width:72rem) {

    .OnePxDiv{
        height:1px;
    }
    
.HeadEnquiryPg{

    font-family: 'Philosopher';
    font-size: 30px;
    font-weight: 600;
    /* margin: 0 auto; */
    line-height: 40px;
    margin-top: 16rem;
    color: #D1181F;
    /* position: fixed; */
    /* position: absolute; */
    /* left: 50%; */
    
    float: right;
    width: 50%;
    /* z-index: -1; */

}

}


/* ****************************** */

@media screen and (max-width:72rem) {

    .OnePxDiv{
        height:1px;
    }
    .HeadEnquiryPg{

        font-family: 'Philosopher';
        font-size: 1.4rem;
        font-weight: 600;
        /* margin: 0 auto; */
        line-height: 40px;
        margin-top: 10rem;
        color: #D1181F;
        
        /* position:absolute; */
        /* left: 50%; */
        /* width: 70%; */
        /* margin-left: 2rem; */
        text-align: center;

    
        /* z-index: -1; */
    }

    

.EnqFormWid1Div{
    
    width: 94%;
    /* float: right; */
    display: flex;
    justify-content: center;
    margin: 0 auto;
    
}


.EnqFormWid1{
    width: 25rem;
    border-radius: 2rem;
    border: 1px solid #D1181F;
    height: auto ;
    margin-top: -10rem;
    background-color: white;
    opacity: 0.85;
    
    /* right: 50%; */
    /* position: relative; */
    /* left: 50%; */
    margin-bottom: 3rem;

    
}


    
}
