@media screen and (max-width:45rem) {

    .HeadAbledPg{

        font-family: 'Philosopher';
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        line-height: auto;
        padding-top: 300px;
        color: #D1181F;
        width: 70%;
        
    }
    .SubHeadAbledPg{
        font-family: 'Philosopher';
        font-size: 1rem;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5rem;
        padding-top: .1rem;
        color: #D1181F;
    
    }
    
    .BgAbledPg{
        
        background-color: rgb(252, 252, 252);
        width: auto;
        height: 632px;
        /* display: flex; */
        background-image: url('./Images/BgImgAbled.png');
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center;
    
    }
    
    
    .BgAbledSecHome{
    
        background-color:white;
        height: auto;
        width: 100%;
        
            
    }
    
    /* .BoxAbledSecHome{
    
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    
    } */
    
    
    .BoxAbledSecHome{
    
        width: 600px;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem ;
        margin-bottom: 1rem;
        border-radius: 24px;
        height: auto;
        width: auto;
        /* background-color: cornsilk; */
        /* border: 1.5px solid rgb(169, 20, 20); */
        border: 1.5px solid rgb(195, 195, 195);
        /* background-image: url('./Images/BoxAbledSecHomeBgImg.png'); */
    
    }
    
    
    .HeadAbledSecHome{
    
        font-family: 'Philosopher';
        font-size: 20px;
        font-weight: 800;
        /* text-align: center; */
        padding-top: 200px ;
        padding-left: 40px;
        padding-top: 40px;
        color: #D1181F;
    
    }
    
    .BtAbledSecHome{
        font-family: 'Philosopher';
        font-size: 14px;
        font-weight: 800;
        color: #D1181F;
        right: 20px;
        top:20px;
        
        padding-left: 1rem;
        padding-top: 15px ;
        padding-bottom: 1rem;
    
    
    }
    
    .AbledImg101{
        width: 80%;
        padding-top: 60px;
    
    }
    
    .AbledImg102{
        width: 20%;
        padding-top: 20px;
        padding-bottom: -10px;
        margin-left: 60px;
    }
    
    
    .SecAbledIntro{
        border: 1.5px #D1181F solid;
        border-radius: 2rem;
        height: auto;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        /* margin: .4rem; */
        margin-top: -7rem;
        margin-bottom: 2rem;
        background-color: white;
        opacity: 0.9;
        display: block;

    
    }
    
    .TextAbledBanner{
    
            font-family: 'Philosopher', sans-serif;
            font-size: .8rem;
            font-weight: 300;
            color:rgb(51, 51, 51);
            width: 100%;
            margin: 1rem;
            text-align: ;
            line-height: 1.4rem;
            margin-top: 6rem;
    }

    .LinkAbledBanner{
        font-family: 'Philosopher', sans-serif;
        font-size: .8rem;
        font-weight: 800;
        color:rgb(51, 51, 51);
        /* padding: 1rem; */
        margin: 2rem 1rem;
    
    }
    .LinkAbledBanner a{
        text-decoration: none;
        color:rgb(218, 38, 38);
    }
    
    .ImgDiv{
        /* width: 90%; */
    }
    .ImgLogoAbled{
        
        width: 50%;
        height: 50%;
        margin-top: ;
        margin-left: 1rem;
        margin-bottom:1rem;
        /* margin-left: 5rem;   */
    }
    
    .SecAbledIntro .TextDiv{
        width: 90%;
    }

    .AllCardsAbled{
        display: block;
        /* flex-wrap: wrap; */
        width: 100%;
        margin: 0 auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: .4rem;

    }
    
    .CardAbled{
        border-radius: 1rem;
        border: 1px solid #D1181F;
        width: 90%;
        height: auto;
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;
        /* margin: 0 auto; */
    }
    
    .CardAbled:hover {
       
            background-color: #D1181F;
        
    }
    
    .CardAbled:hover .CardHead{
            
        color: white;
    
    }
    
    
    .CardHead{
    
        font-family: 'Philosopher', sans-serif;
        font-weight: 600;
        color: #D1181F;
        text-align: center;
        margin: 1rem;
    
    }
    .CardAbledImgDiv{
        display: flex;
        justify-content: center;

    }
    .CardAbledImg{
    
        width: 100%;
        height: 100%;
        margin-bottom: -5px; 
        /* margin-top: -5.1rem; */
    
    }
    
   
    
}


@media screen  and (min-width:45rem) {


.HeadAbledPg{

    font-family: 'Philosopher';
    font-size: 30px;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    padding-top: 300px;
    color: #D1181F;
    

}
.SubHeadAbledPg{
    font-family: 'Philosopher';
    font-size: 1rem;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5rem;
    padding-top: .1rem;
    color: #D1181F;

}

.BgAbledPg{
    
    background-color: rgb(252, 252, 252);
    width: auto;
    height: 632px;
    /* display: flex; */
    background-image: url('./Images/BgImgAbled.png');
    background-repeat:no-repeat;
    background-size: contain;

}



.BgAbledSecHome{

    background-color:white;
    height: 512px;
    width: 100%;
    
        
}

/* .BoxAbledSecHome{

    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;

} */


.BoxAbledSecHome{

    /* width: 600px; */
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem ;
    margin-bottom: 2rem;
    border-radius: 24px;
    height: auto;
    width: auto;
    /* background-color: cornsilk; */
    border: 1.5px solid rgb(218, 38, 38);
    /* background-image: url('./Images/BoxAbledSecHomeBgImg.png'); */

}


.HeadAbledSecHome{

    font-family: 'Philosopher';
    font-size: 20px;
    font-weight: 800;
    /* text-align: center; */
    padding-top: 200px ;
    padding-left: 40px;
    padding-top: 40px;
    color: #D1181F;

}

.BtAbledSecHome{
    font-family: 'Philosopher';
    font-size: 14px;
    font-weight: 800;
    color: #D1181F;
    right: 20px;
    top:20px;
    /* position: fixed; */
    
    padding-left: 40px;
    padding-top: 15px ;
    padding-bottom: 15px;


}

.AbledImg101{
    width: 80%;
    padding-top: 60px;

}

.AbledImg102{
    width: 10%;
    padding-top: 20px;
    padding-bottom: -10px;
    margin-left: 60px;
}


.SecAbledIntro{
    border: 1.5px #D1181F solid;
    border-radius: 2rem;
    height: 532px;
    margin: 3rem;
    margin-top: -7rem;
    background-color: white;
    opacity: 0.9;
    display: flex;

}

.TextAbledBanner{

        font-family: 'Philosopher', sans-serif;
        font-size: 1rem;
        font-weight: 800;
        color:rgb(51, 51, 51);
        width: 25rem;
        margin: 3rem;
        text-align: ;
        line-height: 1.6rem;
        margin-top: 6rem;
}
.LinkAbledBanner{
    font-family: 'Philosopher', sans-serif;
    font-size: .8rem;
    font-weight: 800;
    color:rgb(51, 51, 51);
    /* padding: 1rem; */
    margin: 3rem;

}
.LinkAbledBanner a{
    text-decoration: none;
    color:rgb(218, 38, 38);
}

.ImgDiv{
    width: 50%;
}
.ImgLogoAbled{
    
    width: 400px;
    height: 200px;
    margin-top: 10rem;
    margin-left: 5rem;  
}

.SecAbledIntro .TextDiv{
    width: 50%;
}

.CardAbled{
    border-radius: 1rem;
    border: 1px solid #D1181F;
    width: 550px;
    height: 450px;
    margin: 1rem;
}
.CardAbled:hover {
   
        background-color: #D1181F;
    
}

.CardAbled:hover .CardHead{
        
    color: white;

}


.CardHead{

    font-family: 'Philosopher', sans-serif;
    font-weight: 600;
    color: #D1181F;
    text-align: center;
    margin: 2rem;

}

.CardAbledImg{

    width: 550px;
    height: 450px;
    margin-top: -5.1rem;

}

.AllCardsAbled{
    display: flex;
    flex-wrap: wrap;
    width: 80rem;
    /* margin: 0 auto; */
    margin-left: 10%;
    margin-right: 10%;
}

}

