@media screen and (min-width:45rem) {
    

.HeadHimalayanPg{

    font-family: 'Philosopher';
    font-size: 30px;
    font-weight: 600;
    margin: 0 auto;
    line-height: 40px;
    padding-top: 300px;
    color: #D1181F;
    text-align: center;

}

.SubHeadHimalayanPg{
    font-family: 'Philosopher';
    font-size: 1rem;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5rem;
    padding-top: .1rem;
    color: #D1181F;


}

.BgHimalayanPg{
    
    /* background-color: rgb(252, 252, 252); */
    width: auto;
    height: 632px;
    /* display: flex; */
    background-image: url('./Images/BgImgHimalayan.png');
    background-size: contain;

}


.BgHimalayanSecHome{

    background-color:white;
    height: 512px;
    width: 100%;
    
        
}




.BoxHimalayanSecHome{

    width: 600px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 24px;
    height: 450px;
    width: auto;
    /* background-color: cornsilk; */
    border: 1.5px solid rgb(218, 38, 38);
   

}


.widthFaIcon{
    padding-left: 10px;
    padding-right:10px;
}


.HeadHimalayanSecHome{

    font-family: 'Philosopher';
    font-size: 20px;
    font-weight: 800;
    /* text-align: center; */
    padding-top: 200px ;
    padding-left: 40px;
    padding-top: 40px;
    color: #D1181F;

}

.BtHimalayanSecHome{
    font-family: 'Philosopher';
    font-size: 14px;
    font-weight: 800;
    color: #D1181F;
    right: 20px;
    top:20px;

    padding-left: 40px;
    /* padding-top: 15px ; */
    padding-bottom: 15px;

    


}

.Img101{
    width: 100%;
    padding-top: 30px;

}

.Img102{
    width: 8%;
    margin-top:-70px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 60px;
}



.SecHimIntro{
    border: 1.5px #D1181F solid;
    border-radius: 2rem;
    height: 532px;
    margin: 3rem;
    margin-top: -7rem;
    background-color: white;
    opacity: 0.9;
    display: flex;

}


.TextHimBanner{

    font-family: 'Philosopher', sans-serif;
    font-size: 1rem;
    font-weight: 800;
    color:rgb(51, 51, 51);
    width: 25rem;
    margin: 3rem;
    text-align: ;
    line-height: 1.6rem;
    margin-top: 6rem;
}
.LinkHimBanner{
    font-family: 'Philosopher', sans-serif;
    font-size: .8rem;
    font-weight: 800;
    color:rgb(51, 51, 51);
    /* padding: 1rem; */
    margin: 3rem;

}

.LinkHimBanner a{
    text-decoration: none;
    color:rgb(218, 38, 38);
}

.ImgDiv{
    width: 50%;
}

.ImgLogoHim{

    width: 400px;
    height: 200px;
    margin-top: 10rem;
    margin-left: 5rem;  
}


.SecHimIntro .TextDiv{
    width: 50%;
}

.CardHim{
    border-radius: 1rem;
    border: 1px solid #D1181F;
    width: 550px;
    height: 450px;
    margin: 1rem;
}

.CardHim:hover {
    background-color: #a5dbf5;
    border: 1px solid #a5dbf5;
    
}

.CardHim:hover .CardHead{
    
    color: darkblue;

}

.CardHead{

    font-family: 'Philosopher', sans-serif;
    font-weight: 300;
    color: #D1181F;
    text-align: center;
    margin: 2rem;

}

.CardHimImg{

    width: 550px;
    height: 450px;
    margin-top: -5.1rem;

}

.AllCardsHim{
    display: flex;
    flex-wrap: wrap;
    width: 80rem;
    /* margin: 0 auto; */
    margin-left: 10%;
    margin-right: 10%;
}

}


/* *********************************** */


@media screen and (max-width:45rem) {

    .HeadHimalayanPg{
        font-family: 'Philosopher';
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        line-height: auto;
        padding-top: 300px;
        color: #D1181F;
        width: 70%;
    }
    
    .SubHeadHimalayanPg{
        font-family: 'Philosopher';
        font-size: 1rem;
        font-weight: 600;
        margin: 0 auto;
        margin-top:.6rem;
        text-align: center;
        line-height: 1.5rem;
        padding-top: .1rem;
        color: #D1181F;
    
    }
    
    
    .BgHimalayanPg{
        
        /* background-color: rgb(252, 252, 252); */
        width: auto;
        height: 632px;
        /* display: flex; */
        background-image: url('./Images/BgImgHimalayan.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    
    .BgHimalayanSecHome{
    
        background-color:white;
        /* height: 512px; */
        height: auto;
        width: 100%;
        
            
    }
 
    
    .BoxHimalayanSecHome{
    
        /* width: 600px; */
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: 1.5rem;
        height: 20rem;
        width: auto;
        /* background-color: cornsilk; */
        /* border: 1.5px solid rgb(218, 38, 38); */
        border: 1.5px solid rgb(195, 195, 195);
    
    }
    
    
    .widthFaIcon{
        padding-left: 10px;
        padding-right:10px;
    }
    
    
    .HeadHimalayanSecHome{
    
        font-family: 'Philosopher';
        font-size: 1rem;
        font-weight: 400;
        /* text-align: center; */
        padding-top: 2rem ;
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        color: #D1181F;
        width: 90%;
    
    }
    
    .BtHimalayanSecHome{
        font-family: 'Philosopher';
        font-size: 14px;
        font-weight: 800;
        color: #D1181F;
        right: 20px;
        top:20px;
                
        padding-left: 1rem;
        padding-top: 15px ;
        padding-bottom: 15px;

    
    }
    
    .Img101{
        width: 100%;
        padding-top: 98px;
    
    }
    
    .Img102{
        width: 14%;
        margin-top:-70px;
        padding-top: 20px;
        padding-bottom: 5px;
        margin-left: 50px;
    }
    
    
    
    .SecHimIntro{
        border: 1.5px #D1181F solid;
        border-radius: 2rem;
        height: auto;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        /* margin: .4rem; */
        margin-top: -7rem;
        margin-bottom: 2rem;
        background-color: white;
        opacity: 0.9;
        display: block;
    }
    
    
    .TextHimBanner{

        
        font-family: 'Philosopher', sans-serif;
        font-size: .8rem;
        font-weight: 300;
        color:rgb(51, 51, 51);
        width: 100%;
        margin: 1rem;
        text-align: ;
        line-height: 1.4rem;
        margin-top: 6rem;

    }

    .LinkHimBanner{
        font-family: 'Philosopher', sans-serif;
        font-size: .8rem;
        font-weight: 800;
        color:rgb(51, 51, 51);
        /* padding: 1rem; */
        margin: 2rem 1rem;
    
    }
    
    .LinkHimBanner a{
        text-decoration: none;
        color:rgb(218, 38, 38);
    }
    
    .ImgDiv{
        /* width: 50%; */
    }
    
    .ImgLogoHim{
    
        width: 50%;
        height: 50%;
        margin-top: ;
        margin-left: 1rem;
        margin-bottom:1rem;
        /* margin-left: 5rem;   */
    }
    
    
    .SecHimIntro .TextDiv{
        width: 90%;
    }

.AllCardsHim{
    display: block;
    /* flex-wrap: wrap; */
    width: 100%;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: .4rem;
}
    
    .CardHim{
        border-radius: 1rem;
        border: 1px solid #D1181F;
        width: auto;
        height: auto;
        margin: 1rem;
    }
    
    .CardHim:hover {
        background-color: #a5dbf5;
        border: 1px solid #a5dbf5;
        
    }
    
    .CardHim:hover .CardHead{
        
        color: darkblue;
    
    }
    
    .CardHead{
    
        font-family: 'Philosopher', sans-serif;
        font-weight: 300;
        color: #D1181F;
        text-align: center;
        margin: 2rem;
    
    }

    .CardHimImgDiv{
        display: flex;
        justify-content: center;

    }

    .CardHimImg{
    
        
        width: 100%;
        height: auto;
        /* margin-bottom: -5px;  */
        /* margin-top: -5.1rem; */
    
    }
    
    



}
